import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';

import Slider from 'react-slick';

// material-ui
import { useTheme } from '@mui/styles';
import { CardMedia, Typography, useMediaQuery, Box } from '@mui/material';

import formattedPrice from 'utils/formatPrice';
import { getPublicCarsByProperties } from 'api/PublicCarService';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import getCarPhoto from 'utils/carPhotos';
import { useNavigate } from 'react-router';
import MainCard from 'components/MainCard';

// ==============================|| PRODUCT DETAILS - FEATURES ||============================== //

function FeaturedCars({ offerCar }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const matchDownXS = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [availableCars, setAvailableCars] = useState([]);
  const [dragging, setDragging] = useState(false);

  const SLICE_SIZE = 10;

  const NextCustomArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Box
        className={className}
        onClick={onClick}
        sx={{
          ...style,
          display: 'block',
          '&:before': { color: `${theme.palette.primary.main} !important;`, fontSize: '30px !important;' }
        }}
      />
    );
  };

  NextCustomArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
  };

  const PrevCustomArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Box
        className={className}
        onClick={onClick}
        sx={{
          ...style,
          display: 'block',
          '&:before': { color: `${theme.palette.primary.main} !important;`, fontSize: '30px !important;' }
        }}
      />
    );
  };

  PrevCustomArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
  };

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: matchDownSM ? (matchDownXS ? 2 : 3) : 4,
    infinite: true,
    autoplay: true,
    swipeToSlide: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    nextArrow: <NextCustomArrow />,
    prevArrow: <PrevCustomArrow />,
    rows: 1,
    autoplaySpeed: 4000
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const handleOnItemClick = useCallback(
    (e, car) => {
      if (dragging) e.stopPropagation();
      else navigate(`/public-cars/${car.reference}`);
    },
    [dragging, navigate]
  );

  const fetchCars = useCallback(async () => {
    const reduceFeaturedCars = (cars) => {
      let featuredCars = cars;
      if (cars.length > SLICE_SIZE) {
        featuredCars = featuredCars.reduce((result, current) => {
          if (current.brand === offerCar.brand) result.push(current);
          return result;
        }, []);
        if (featuredCars.length > SLICE_SIZE) {
          featuredCars = featuredCars.reduce((result, current) => {
            if (current.model === offerCar.model) result.push(current);
            return result;
          }, []);
          if (featuredCars.length > SLICE_SIZE) {
            featuredCars = featuredCars.slice(0, SLICE_SIZE);
          }
        }
      }
      while (featuredCars.length < 4) {
        featuredCars = [...featuredCars, ...featuredCars];
      }
      return featuredCars;
    };

    getPublicCarsByProperties().then((response) => {
      const cars = response.data.data;
      const featuredCars = reduceFeaturedCars(cars);

      setAvailableCars(featuredCars);
    });
  }, [offerCar]);

  useEffect(() => {
    fetchCars();
  }, [fetchCars]);

  const getCarPhotos = (car) => {
    if (car) {
      if (car.photos?.length > 0) {
        return car.photos[0];
      } else {
        return getCarPhoto(car.brand);
      }
    }
  };

  return (
    <div className="container">
      {availableCars.length > 0 && (
        <Slider {...settings} beforeChange={handleBeforeChange} afterChange={handleAfterChange}>
          {availableCars.map((carItem, index) => (
            <MainCard
              border={false}
              id={`carItem-${index}`}
              key={`carItem-${index}`}
              sx={{ width: '335px', backgroundColor: 'unset' }}
              onClick={(e) => handleOnItemClick(e, carItem)}
            >
              <CardMedia component="img" image={getCarPhotos(carItem)} style={{ padding: 40 }} />
              <Typography className="notranslate" variant="title" color={theme.palette.info.darker}>
                <b>{`${carItem.brand} ${carItem.model}`}</b>
              </Typography>
              <Typography className="notranslate" variant="body1" color={theme.palette.info.darker}>
                {`${carItem.version} `}
              </Typography>
              <Typography variant="body2" color={theme.palette.info.dark} mb={4}>
                <b>{carItem.price && carItem.price != 0 && formattedPrice(carItem.price)}</b>
              </Typography>
            </MainCard>
          ))}
        </Slider>
      )}
    </div>
  );
}

FeaturedCars.propTypes = {
  offerCar: PropTypes.object
};

export default FeaturedCars;
