import PropTypes from 'prop-types';

// material-ui
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import carSourcingWhite from 'assets/images/icons/carSourcingIconWhite.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse }) => {
  const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <>
      <Box width="500px" fill="none" display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <img
          alt="Car Sourcing"
          style={{
            width: '75px',
            marginRight: '1rem',
            color: 'white',
            filter: theme.palette.mode === 'dark' || reverse ? 'invert(1)' : 'invert(-0)'
          }}
          src={carSourcingWhite}
        ></img>

        <Typography variant="h3" fontFamily={'CanoGroup'}>
          CAR SOURCING
        </Typography>
      </Box>
    </>
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
