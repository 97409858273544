/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useState, forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';

// material-ui
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Button,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
  Stack
} from '@mui/material';

// third-party
import { useFormik, Form, FormikProvider } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

// project imports
import useAuth from 'hooks/useAuth';
import useUtils from 'hooks/useUtils';
import getCarPhoto from 'utils/carPhotos';
import formattedPrice from 'utils/formatPrice';
import { postOfferOffer } from 'api/PublicCarService';

import opel from 'assets/images/cars/opel.png';

const NumericFormatCustom = forwardRef(function NumericFormatCustom(props) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      mask="0.000,00"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      valueIsNumericString
      suffix=" €"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const MakeClientOffer = ({ closeDialog, listVoCarsData }) => {
  const intl = useIntl();
  const { user, company } = useAuth();
  const { displaySnackbar } = useUtils();
  const [loading, setLoading] = useState(false);
  const [errorCars, setErrorCars] = useState([]);

  const getInitialValues = (offer_car) => {
    offer_car.price_client_counteroffer = undefined;
    return offer_car;
  };

  const getCarPhotos = (car) => {
    if (car) {
      if (car.photos?.length > 0) {
        return car.photos[0];
      } else {
        return [getCarPhoto(car.brand)];
      }
    }
  };

  const onSubmit = async (values) => {
    const foundCars = values ? values['price'] * 0.6 > parseInt(values['price_client_counteroffer']) : undefined;
    console.log('🚀 ~ onSubmit ~ foundCars:', foundCars);

    setErrorCars(foundCars);
    if (foundCars?.length > 0) {
      return true;
    } else {
      try {
        const res = await postOfferOffer({
          list_vo_offer_info: [{ ...listVoCarsData, offer_price: values.price_client_counteroffer }],
          user_info: user,
          company_info: company
        });

        console.log('🚀 ~ onSubmit ~ res:', res);
        if (res.status === 200) {
          displaySnackbar(intl.formatMessage({ id: 'message-counteroffer-added-successfully' }));
        }

        console.log('🚀 ~ onSubmit ~ closeDialog: VOY A CERRAR');
        closeDialog();
      } catch (error) {
        console.error(error);
        displaySnackbar(intl.formatMessage({ id: 'message-something-went-wrong' }), 'error', true);
        setLoading(false);
      }
    }
  };

  const priceValidation = (values) => {
    const errors = {};

    const offerPrice = parseFloat(values['price']);
    const clientCounteroffer = parseInt(values['price_client_counteroffer']);

    if (isNaN(clientCounteroffer)) {
      errors[`car.price_client_counteroffer`] = 'Please enter a valid counteroffer price.';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: getInitialValues(listVoCarsData),

    onSubmit,
    validate: priceValidation
  });

  const { values, handleSubmit, getFieldProps, setFieldValue, handleBlur, isValid, dirty } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
          <DialogTitle sx={{ p: 4, pt: 4.5, display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '1.2rem' }}>
              <FormattedMessage id="make-client-offer.your-offer" />
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 1, pl: 3, pr: 3 }} style={{ overflow: 'auto', maxHeight: '32vh' }}>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}>
                <Box sx={{ m: 'auto', p: 1 }}>
                  <CardMedia sx={{ height: 100, textDecoration: 'none', opacity: 1 }} image={getCarPhotos(values)} />
                </Box>
              </Grid>
              <Grid item xs={3} md={3} textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
                <Typography className="notranslate" variant="subtitle1">{`${values.brand} ${values.model}`}</Typography>
                <Typography className="notranslate" variant="body2" color="text.secondary">
                  {values.version}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
                <Typography variant="subtitle1">{`${!isNaN(Number(values.price)) ? formattedPrice(values.price) : '--'}`}</Typography>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="price" />
                </Typography>
              </Grid>
              <Grid item xs={3} md={3} textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
                <TextField
                  name={`cars.${'price_client_counteroffer'}`}
                  value={values.price_client_counteroffer}
                  placeholder={`${formattedPrice(0)}`}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                    sx: {
                      '& input': {
                        fontSize: '0.875rem',
                        fontWeight: 600,
                        pt: 0,
                        pb: 0
                      }
                    },
                    style: {
                      textAlignLast: 'center'
                    }
                  }}
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue(`price_client_counteroffer`, e.target.value)}
                  variant="standard"
                />
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="make-client-offer.offered-price" />
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage
                    id="make-client-offer.note"
                    defaultMessage={
                      "En soumettant votre offre d'achat pour un véhicule, vous vous engagez à acquérir le véhicule au prix que vous avez spécifié. Le véhicule reste visible et disponible à l'achat pour tout autre utilisateur de Car-Sourcing au prix de vente initial, jusqu'à ce que nous concluions un accord à votre prix. Notre accord de vente entraînera l'émission de votre bon de commande"
                    }
                  />
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Button color="error" onClick={closeDialog}>
              {<FormattedMessage id="back" />}
            </Button>
            <LoadingButton type="submit" variant="contained" loading={loading} disabled={!isValid || !dirty}>
              {<FormattedMessage id="accept" />}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </>
  );
};

MakeClientOffer.propTypes = {
  closeDialog: PropTypes.func,
  listVoCarsData: PropTypes.object,
  offerId: PropTypes.string
};

export default MakeClientOffer;
