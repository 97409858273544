/* eslint-disable no-unused-vars */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/styles';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Chip, Dialog, Grid, Tooltip, Typography } from '@mui/material';

import formattedPrice from 'utils/formatPrice';
import { addProduct } from 'store/reducers/cart';
import { useStatusConfig } from 'hooks/useStatusConfig';
import MakeClientOffer from 'components/dialogs/client/MakeClientOffer';

// ==============================|| PRODUCT DETAILS - FEATURES ||============================== //

function PlaceOrder({ offerCar }) {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { carOfferStatus } = useStatusConfig();
  const { checkout } = useSelector((state) => state.cart);

  const [openCounterofferDialog, setOpenCounterofferDialog] = useState(false);

  const isCarInCart = (targetId) => checkout.products.some((obj) => obj.id === targetId);
  const onBuy = () => {
    dispatch(addProduct({ products: [offerCar] }));
  };

  const closeCounterofferDialog = () => {
    setOpenCounterofferDialog(false);
  };

  return (
    <Grid container spacing={2} padding={2} alignContent={'center'} justifyContent={'center'} justifyItems={'center'}>
      <Grid item xs={12} alignContent={'center'} textAlign={'center'}>
        <Typography className="notranslate" variant="h2" style={{ fontWeight: 'bold' }} color={theme.palette.info.darker}>
          {offerCar.brand} {offerCar.model}
        </Typography>
        <Typography className="notranslate" variant="h4" color={theme.palette.info.darker}>
          {offerCar.version}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={'2rem'} textAlign={'center'}>
        <Grid container>
          <Grid item xs={12} alignContent={'center'} textAlign={'center'}>
            <Tooltip title={intl.formatMessage({ id: 'available-puchase-cars-list.damage-costs' })}>
              <Typography variant="h5">
                <FormattedMessage id="available-puchase-cars-list.damage-costs" />
                {': '}
                <span style={{ marginLeft: '0.5rem' }}>
                  {offerCar?.damage_estimated_costs ? formattedPrice(offerCar.damage_estimated_costs) : '--'}
                </span>
              </Typography>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: 'available-puchase-cars-list.expertise-link' })}>
              <Typography variant="h5">
                <Button
                  color="info"
                  variant="contained"
                  disabled={!offerCar.assessment_report_link}
                  target="_blank"
                  href={offerCar.assessment_report_link}
                  style={{ padding: '8px', fontSize: '16px', margin: 5 }}
                >
                  <CloudDownloadOutlined style={{ fontSize: '24px', marginRight: 6 }} />
                  <FormattedMessage id="available-puchase-cars-list.expertise-link" />
                </Button>
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} alignContent={'center'} textAlign={'center'}>
        <Grid container id={'purchase-module'} alignContent={'center'} justifyContent={'center'} justifyItems={'center'} mt={2}>
          {offerCar.purchase_enabled && (
            <>
              <Grid item textAlign={'center'} xs={12} style={{ justifyContent: 'center', paddingTop: '1.5rem' }}>
                <Tooltip title={intl.formatMessage({ id: 'available-puchase-cars-list.price' })}>
                  <>
                    <Typography variant="h5">
                      <FormattedMessage id="price-instant" />
                    </Typography>
                  </>
                </Tooltip>
              </Grid>
              <Grid
                item
                alignContent={'center'}
                textAlign={'right'}
                xs={12}
                sm={6}
                lg={6}
                xl={5}
                style={{ padding: '0.5rem', paddingTop: '0.5rem' }}
              >
                <Chip
                  color={offerCar?.price ? 'info' : 'warning'}
                  label={offerCar?.price ? formattedPrice(offerCar.price) : <FormattedMessage id="not-defined" />}
                  style={{ fontWeight: 'bold', fontSize: '25px', width: '100%' }}
                  size="large"
                  variant="outlined"
                />
              </Grid>
            </>
          )}
          <Dialog
            maxWidth="sm"
            fullWidth
            onClose={closeCounterofferDialog}
            open={openCounterofferDialog}
            sx={{ '& .MuiDialog-paper': { p: 0 } }}
          >
            {openCounterofferDialog && <MakeClientOffer closeDialog={closeCounterofferDialog} listVoCarsData={offerCar} />}
          </Dialog>
        </Grid>
      </Grid>
      <Grid item xs={12} alignContent={'center'} textAlign={'center'}>
        <Grid container id={'offre-module'} alignContent={'center'} justifyContent={'center'} justifyItems={'center'} mt={3}>
          {offerCar.offer_enabled && (
            <Grid
              item
              textAlign={'left'}
              alignContent={'center'}
              xs={12}
              sm={6}
              lg={6}
              xl={5}
              style={{ padding: '0.5rem', paddingTop: '0.5rem' }}
            >
              <Button
                color="info"
                variant="outlined"
                disabled={isCarInCart(offerCar.id) || offerCar.status !== carOfferStatus.waiting_for_response.value}
                size="large"
                style={{ width: '100%' }}
                onClick={() => {
                  setOpenCounterofferDialog(true);
                }}
              >
                <FormattedMessage id="make-offer" />
              </Button>
            </Grid>
          )}
          {offerCar.purchase_enabled && (
            <Grid
              item
              textAlign={'left'}
              alignContent={'center'}
              xs={12}
              sm={6}
              lg={6}
              xl={5}
              style={{ padding: '0.5rem', paddingTop: '0.5rem' }}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={isCarInCart(offerCar.id) || offerCar.status !== carOfferStatus.waiting_for_response.value}
                style={{ fontWeight: 'bold', width: '100%' }}
                size="large"
                onClick={onBuy}
              >
                <FormattedMessage id="add-to-cart" />
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item id={'notes-module'} textAlign={'center'} xs={12} marginTop={'1rem'}>
        <Grid container spacing={2}></Grid>
        <Grid container>
          <Grid item textAlign={'center'} xs={12} style={{ justifyContent: 'center', paddingTop: '0.5rem' }}>
            <em>
              <FormattedMessage id="price-ttc" />,{' '}
              <FormattedMessage id={offerCar?.rebu ? 'price-ttc-recuperable' : 'price-ttc-not-recuperable'} />
            </em>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PlaceOrder.propTypes = {
  offerCar: PropTypes.object
};

export default PlaceOrder;
