import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState();

  const { drawerOpen, openItems, userRole } = useSelector((state) => state.menu);

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = { component: forwardRef((props, ref) => <Link {...props} to={item.url} target={itemTarget} ref={ref} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const { pathname } = useLocation();

  useEffect(() => {
    setIsSelected(openItems[userRole].findIndex((id) => id === item.id) > -1);
  }, [openItems, item, userRole]);

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes('product-details')) {
      if (item.url && item.url.includes('product-details')) {
        dispatch(activeItem({ userRole, openItem: [item.id] }));
      }
    }

    if (pathname.includes(item.url)) {
      dispatch(activeItem({ userRole, openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname, userRole]);

  // const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
  const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : theme.palette.primary.main;

  return (
    <>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        selected={isSelected}
        sx={{
          height: '100%',
          zIndex: 1201,
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: `${theme.palette.primary.lighter}66`,
              borderBottom: `2px solid ${theme.palette.primary.main}`
            },
            '&.Mui-selected': {
              bgcolor: `${theme.palette.primary.lighter}22`,
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor,
                bgcolor: `${theme.palette.primary.lighter}33`
              }
            }
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: `${theme.palette.primary.lighter}66`,
              borderBottom: `2px solid ${theme.palette.primary.main}`
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: `${theme.palette.primary.lighter}22`
              },
              bgcolor: `${theme.palette.primary.lighter}11`
            }
          })
        }}
      >
        <ListItemText
          primary={
            <Typography variant="h6" color="inherit">
              {item.title}
            </Typography>
          }
        />
        {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
