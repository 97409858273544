import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing

const Info = Loadable(lazy(() => import('pages/info')));

// ==============================|| PROVIDER ROUTING ||============================== //

const InfoRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <Info />
    },
    {
      path: 'contact-us',
      element: <Info />
    }
  ]
};

export default InfoRoutes;
