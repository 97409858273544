import { getGeocode } from 'use-places-autocomplete';

let autocompleteService = null;
let placesService = null;

const getPlacePredictions = (input, callback) => {
  try {
    if (!autocompleteService) {
      autocompleteService = new window.google.maps.places.AutocompleteService();
    }
    autocompleteService.getPlacePredictions(input, callback);
  } catch (error) {
    console.error(error);
  }
};

const getPlaceDetails = async (options, callback) => {
  try {
    if (!placesService) {
      let map = new window.google.maps.Map(document.createElement('div'));
      placesService = new window.google.maps.places.PlacesService(map);
    }

    placesService.getDetails(options, callback);
  } catch (error) {
    console.error(error);
  }
};

const geocode = async (options) => {
  const [details] = await getGeocode(options);
  const findItem = (type) => details.address_components.find((item) => item.types[0] === type);

  const location = {
    country: findItem('country')?.short_name,
    department: findItem('administrative_area_level_2')?.long_name,
    region: findItem('administrative_area_level_1')?.long_name,
    locality: findItem('locality')?.long_name,
    postal_code: findItem('postal_code')?.long_name
  };

  return location;
};

export { getPlacePredictions, getPlaceDetails, geocode };
