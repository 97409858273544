/* eslint-disable no-unused-vars */
import { FormattedMessage, useIntl } from 'react-intl';
// material-ui
import { Grid, List, ListItem, ListItemText, Tabs, Tab, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';

import MainCard from 'components/MainCard';
import formatKilometers from 'utils/formatKms';
import { BarsOutlined, ControlOutlined } from '@ant-design/icons';
import { useState } from 'react';

// ==============================|| PRODUCT DETAILS - FEATURES ||============================== //

function OfferCarOptions({ offerCar }) {
  const intl = useIntl();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState('options');

  const parseDescription = () => {
    const results = offerCar.description.split(';');
    if (results.length < 10) {
      for (let index = results.length; index < 10; index++) {
        results.push('');
      }
    }
    return results;
  };

  const isEven = (n) => {
    n = Number(n);
    return n === 0 || !!(n && !(n % 2));
  };

  return (
    <>
      {offerCar.description.length > 0 && (
        <MainCard sx={{ p: 1, pb: 0 }}>
          <Grid container style={{ height: 320 }}>
            <Grid item xs={12} mb={3}>
              <Typography variant="h3" color={theme.palette.info.darker}>
                <FormattedMessage id="Options" />:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List sx={{ width: 1, height: 260, overflow: 'hidden', overflowY: 'scroll' }}>
                {parseDescription().map((descItem, index) => (
                  <ListItem
                    key={`item-${index}`}
                    disablePadding
                    style={{
                      padding: 3,
                      paddingLeft: 16,
                      height: 'fit-content',
                      minHeight: 33,
                      backgroundColor: `${isEven(index) ? theme.palette.info.lighter : 'white'}`
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className="notranslate" variant="body1" style={{ overflowWrap: 'break-word' }}>
                          {descItem}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
}

OfferCarOptions.propTypes = {
  offerCar: PropTypes.object
};

export default OfferCarOptions;
