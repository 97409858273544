import PropTypes from 'prop-types';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const handleListItemClick = (item) => {
    switch (item) {
      case 'purchases':
        navigate('/orders/my-purchases');
        break;
      case 'profile':
        navigate('/user/personal');
        break;

      default:
        break;
    }
    item;
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton onClick={() => handleListItemClick('purchases')}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary={`${intl.formatMessage({ id: 'my-purchases' })}`} />
      </ListItemButton>
      <ListItemButton onClick={() => handleListItemClick('profile')}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary={`${intl.formatMessage({ id: 'my-profile' })}`} />
      </ListItemButton>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
