import PropTypes from 'prop-types';
import { createIntl } from 'react-intl';

const numberIntl = createIntl({
  locale: 'es',
  currency: 'EUR'
});

const formattedPrice = (value) =>
  numberIntl.formatNumber(value, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

formattedPrice.propTypes = {
  value: PropTypes.number
};

export default formattedPrice;
