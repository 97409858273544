import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const ReactTrucksTable = Loadable(lazy(() => import('pages/transporter/trucksTable')));
const EntryRequestsTable = Loadable(lazy(() => import('pages/transporter/entryRequestsTable')));
const MyRequestsTable = Loadable(lazy(() => import('pages/transporter/myRequestsTable')));
const RequestDetails = Loadable(lazy(() => import('pages/transporter/requestDetails')));
const TruckDetails = Loadable(lazy(() => import('pages/transporter/truckDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const TransporterRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <EntryRequestsTable />
    },
    {
      path: 'transports',
      children: [
        {
          path: 'trucks-in-progress',
          element: <ReactTrucksTable />
        },
        {
          path: 'trucks-in-progress/:id',
          element: <TruckDetails />
        }
      ]
    },
    {
      path: 'requests',
      children: [
        {
          path: 'entry-requests',
          element: <EntryRequestsTable />
        },
        {
          path: 'entry-requests/:id',
          element: <RequestDetails />
        },
        {
          path: 'my-requests',
          element: <MyRequestsTable />
        },
        {
          path: 'my-requests/:id',
          element: <RequestDetails />
        }
      ]
    }
  ]
};

export default TransporterRoutes;
