// material-ui
import { FormattedMessage } from 'react-intl';
import { Link, Stack, Typography, useMediaQuery } from '@mui/material';

import useConfig from 'hooks/useConfig';

const Footer = () => {
  const { i18n } = useConfig();
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Stack justifyContent="space-between" sx={{ m: '1.5rem', mb: '1rem', p: '24px 16px 0px' }}>
      <Stack
        direction={matchDownSM ? 'column' : 'row'}
        justifyContent={matchDownSM ? 'center' : 'space-between'}
        spacing={2}
        textAlign={matchDownSM ? 'center' : 'inherit'}
      >
        <Typography variant="subtitle2" color="secondary" component="span">
          <FormattedMessage id="auth-footer.this-site-is-protected-by" />{' '}
          <Typography hreflang={i18n} component={Link} variant="subtitle2" href={`/privacy?lang=${i18n}`} target="_blank" underline="hover">
            <FormattedMessage id="auth-footer.privacy-policy" />
          </Typography>
        </Typography>

        <Stack direction={matchDownSM ? 'column' : 'row'} spacing={0.5} textAlign={matchDownSM ? 'center' : 'inherit'}>
          <Typography variant="subtitle2" color="secondary">
            <FormattedMessage id="auth-footer.terms-and-conditions" />
          </Typography>
          <Typography
            variant="subtitle2"
            color="info"
            hreflang={i18n}
            component={Link}
            href={`/terms?lang=${i18n}`}
            target="_blank"
            underline="hover"
          >
            <FormattedMessage id="auth-footer.terms-and-conditions-generic" />
          </Typography>
          <Typography variant="subtitle2" color="secondary">
            <FormattedMessage id="auth-register.end" />
          </Typography>
          <Typography
            variant="subtitle2"
            color="info"
            hreflang={i18n}
            component={Link}
            href={`/terms-vente?lang=${i18n}`}
            target="_blank"
            underline="hover"
          >
            <FormattedMessage id="auth-footer.terms-and-conditions-vente" />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
