import PropTypes from 'prop-types';

// project import
import Theme7 from './theme7';
import ThemeClient from './themeClient';
import ThemeProvider from './themeProvider';

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors, userRole, mode) => {
  switch (userRole) {
    case 'info':
      return Theme7(colors, mode);
    case 'provider':
      return ThemeProvider(colors, mode);
    case 'client':
      return ThemeClient(colors, mode);
    default:
      return ThemeClient(colors, mode);
  }
};

Theme.propTypes = {
  colors: PropTypes.object,
  presetColor: PropTypes.any
};

export default Theme;
