// import { useState } from 'react';
import PropTypes from 'prop-types';

// third-party
import { FormattedMessage } from 'react-intl';

// material-ui
import {
  Box,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
  DialogActions,
  Button,
  Grid,
  List,
  CardMedia,
  ListItemButton,
  ListItemText
} from '@mui/material';

import getCarPhoto from 'utils/carPhotos';
import formattedPrice from 'utils/formatPrice';

// ==============================|| CONFIRM DIALOG ||============================== //

export default function NotAvailableOfferDialog({ closeDialog, notAvailableCarsData }) {
  // sx styles
  const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    transform: 'none'
  };

  const getCarPhotos = (car) => {
    if (car) {
      if (car.photos?.length > 0) {
        return car.photos[0];
      } else {
        return [getCarPhoto(car.brand)];
      }
    }
  };

  const carDescriptionWidth = 'calc(8/10*100%)';
  const carDescriptionMaxWidth = 300;

  return (
    <Box sx={{ p: 1, py: 1.5, minWidth: '500px', minHeight: '200px' }}>
      <DialogTitle sx={{ p: 3, justifyContent: 'start' }}>
        <Typography sx={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '1.2rem' }}>
          <FormattedMessage id="not-available-offer.tittle" />
        </Typography>
        <Typography sx={{ fontWeight: '500', fontSize: '1.2rem' }}>
          <FormattedMessage id="not-available-offer.subtittle" />
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 0, pr: 1 }} style={{ overflow: 'auto', maxHeight: '32vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {notAvailableCarsData.length ? (
              <List
                sx={{
                  py: 0,
                  '& .MuiListItemButton-root': {
                    '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                  }
                }}
              >
                {notAvailableCarsData.map((car, index) => (
                  <ListItemButton
                    divider={index < notAvailableCarsData.length - 1}
                    key={index}
                    sm={12}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box sx={{ width: 110, mr: 1 }}>
                      <CardMedia sx={{ height: 100, width: 110, textDecoration: 'none', opacity: 1 }} image={getCarPhotos(car)} />
                    </Box>
                    <ListItemText
                      primary={<Typography className="notranslate" variant="subtitle1">{`${car.brand} ${car.model}`}</Typography>}
                      secondary={
                        <Typography variant="body2" color="text.secondary">
                          {car.version}
                        </Typography>
                      }
                      sx={{ width: carDescriptionWidth, maxWidth: carDescriptionMaxWidth }}
                    />
                    <ListItemText
                      primary={<Typography variant="subtitle1">{`${car.price !== null ? formattedPrice(car.price) : '--'}`}</Typography>}
                      sx={{ width: 'calc(2/10*100%)', pl: 1 }}
                    />
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2.5 }}>
        <Button disabled={notAvailableCarsData.length === 0} onClick={closeDialog} type="submit" variant="contained">
          <FormattedMessage id="accept" />
        </Button>
      </DialogActions>
    </Box>
  );
}

NotAvailableOfferDialog.propTypes = {
  closeDialog: PropTypes.func,
  notAvailableCarsData: PropTypes.array
};
