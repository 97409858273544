/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';

// material-ui
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';

// third-party
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';
import { useIntl } from 'react-intl';

// project import
import { EnvironmentOutlined } from '@ant-design/icons';
import { getPlacePredictions, geocode } from 'api/GooglePlacesAutocomplete';

// ==============================|| GOOGLE MAP - AUTOCOMPLETE ||============================== //
const countriesPredictions = ['uk', 'es', 'it', 'de', 'fr'];

const GoogleMaps = ({ initialValue, disabled, onChange, onGetDetails, id }) => {
  const intl = useIntl();
  const [value, setValue] = useState('');
  const [initialSet, setInitialSet] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue || '');
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () =>
      throttle(
        (request, callback) => getPlacePredictions({ ...request, componentRestrictions: { country: countriesPredictions } }, callback),
        200
      ),
    []
  );

  useEffect(() => {
    setInitialSet(false);
    setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
          if (!initialSet && initialValue) {
            const newValue = results[0];
            setInitialSet(true);
            setValue(newValue || '');
          }
        }

        const uniqueOptions = [...new Map(newOptions.map((item) => [item.place_id, item])).values()];
        setOptions([...uniqueOptions]);
      }
    });

    return () => (active = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id={id}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      isOptionEqualToValue={(option, value) => value === undefined || value === '' || value.place_id === option.place_id}
      autoComplete
      fullWidth
      autoHighlight
      includeInputInList
      filterSelectedOptions
      disabled={disabled}
      value={value || null}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue);

        if (newValue && onGetDetails) {
          geocode({ address: newValue?.description }).then((results) => onGetDetails(results));
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder={intl.formatMessage({ id: 'google-map.search-your-company-address' })} fullWidth />
      )}
      renderOption={(props, option) => {
        props.key = props.key + option.place_id;
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = matches
          ? parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length])
            )
          : [];

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={EnvironmentOutlined} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

GoogleMaps.propTypes = {
  onChange: PropTypes.func,
  onGetDetails: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  initialValue: PropTypes.string
};

export default GoogleMaps;
