import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';
import { CircularProgress } from '@mui/material';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const { isLoggedIn, isInitialized } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const redirectUrl = search ? `${pathname}${search}` : pathname;

  useEffect(() => {
    if (!isLoggedIn && isInitialized) {
      navigate('login', { replace: true, state: { redirectUrl } });
    }
  }, [isLoggedIn, navigate, pathname, isInitialized, redirectUrl]);

  return isLoggedIn ? (
    children
  ) : (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={80} />
    </div>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
