/* eslint-disable no-unused-vars */
import { FormattedMessage, useIntl } from 'react-intl';
// material-ui
import { Grid, List, ListItem, ListItemText, Tabs, Tab, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';

import MainCard from 'components/MainCard';
import formatKilometers from 'utils/formatKms';
import { BarsOutlined, ControlOutlined } from '@ant-design/icons';
import { useState } from 'react';

// ==============================|| PRODUCT DETAILS - FEATURES ||============================== //

function OfferCarComments({ offerCar }) {
  const intl = useIntl();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState('options');

  return (
    <>
      <MainCard sx={{ p: 1, pb: 0 }}>
        <Grid container>
          <Grid item xs={12} mb={3}>
            <Typography variant="h3" color={theme.palette.info.darker}>
              <FormattedMessage id="Comments" />:
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Typography variant="body1">
              <em>{offerCar.comments}</em>
            </Typography>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}

OfferCarComments.propTypes = {
  offerCar: PropTypes.object
};

export default OfferCarComments;
