import PropTypes from 'prop-types';
import { createIntl } from 'react-intl';

const numberIntl = createIntl({
  locale: 'es'
});

const formatKilometers = (value) =>
  numberIntl.formatNumber(value, {
    style: 'unit',
    unit: 'kilometer',
    unitDisplay: 'short'
  });

formatKilometers.propTypes = {
  value: PropTypes.number
};

export default formatKilometers;
