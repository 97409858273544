import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Error404 from 'pages/undefined-pages/404';
import Error403 from 'pages/undefined-pages/403';
import Error500 from 'pages/undefined-pages/500';
import TabCompany from 'sections/apps/profiles/user/TabCompany';

// pages profile
const Profile = Loadable(lazy(() => import('pages/apps/profiles/user')));
const TabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const TabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));
const TabAddresses = Loadable(lazy(() => import('sections/apps/profiles/user/TabAddresses')));

// ==============================|| APPS ROUTING ||============================== //

const AppsRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'user',
      element: <Profile />,
      children: [
        {
          path: 'personal',
          element: <TabPersonal />
        },
        {
          path: 'company',
          element: <TabCompany />
        },
        {
          path: 'settings',
          element: <TabSettings />
        },
        {
          path: 'addresses',
          element: <TabAddresses />
        }
      ]
    },
    {
      path: '404',
      element: <Error404 />
    },
    {
      path: '403',
      element: <Error403 />
    },
    {
      path: '500',
      element: <Error500 />
    },
    {
      path: '*',
      element: <Error404 />
    }
  ]
};

export default AppsRoutes;
