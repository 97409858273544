import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

// project import
import AppsRoutes from './AppsRoutes';
import InfoRoutes from './InfoRoutes';
import LoginRoutes from './LoginRoutes';
import ClientRoutes from './ClientRoutes';
import ProviderRoutes from './ProviderRoutes';
import TransporterRoutes from './TransporterRoutes';
import TermsRoutes from './TermsRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { userRole } = useSelector((state) => state.menu);

  const serviceRoutes = [AppsRoutes, LoginRoutes, TermsRoutes];

  const routesByUserRole = {
    client: [ClientRoutes],
    provider: [ProviderRoutes],
    transporter: [TransporterRoutes],
    info: [InfoRoutes]
  };

  const routes = useRoutes(routesByUserRole[userRole] ? [...routesByUserRole[userRole], ...serviceRoutes] : serviceRoutes);
  return routes;
}
