import { FormattedMessage } from 'react-intl';
// material-ui
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';

import MainCard from 'components/MainCard';
import formatKilometers from 'utils/formatKms';

// ==============================|| PRODUCT DETAILS - FEATURES ||============================== //

function OfferCarFeatures({ offerCar }) {
  const theme = useTheme();

  return (
    <>
      <MainCard sx={{ p: 1, pb: 0 }}>
        <Grid container style={{ height: offerCar.description.length > 0 ? 320 : '100%' }}>
          <Grid item xs={12} mb={3}>
            <Typography variant="h3" color={theme.palette.info.darker}>
              <FormattedMessage id="details" />:
            </Typography>
          </Grid>
          <Grid item xs={offerCar.description.length > 0 ? 12 : 12} sm={offerCar.description.length > 0 ? 12 : 6}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography color="textSecondary">
                  <FormattedMessage id="offer-car-features.brand" />:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="notranslate">{offerCar?.brand || ''}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography color="textSecondary">
                  <FormattedMessage id="offer-car-features.model" />:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="notranslate">{offerCar?.model || ''}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography color="textSecondary">
                  <FormattedMessage id="offer-car-features.version" />:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="notranslate">{offerCar?.version || ''}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography color="textSecondary">
                  <FormattedMessage id="offer-car-features.box" />:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography>{offerCar?.box}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography color="textSecondary">
                  <FormattedMessage id="offer-car-features.matriculation-date" />:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography>{offerCar?.matriculation_date || ''}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={offerCar.description.length > 0 ? 12 : 12} sm={offerCar.description.length > 0 ? 12 : 6} mt={1}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography color="textSecondary">
                  <FormattedMessage id="offer-car-features.color" />:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography>{offerCar?.color}</Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography color="textSecondary">
                  <FormattedMessage id="offer-car-features.kms" />:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography>{offerCar?.kms ? formatKilometers(offerCar?.kms) : '-'}</Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography color="textSecondary">
                  <FormattedMessage id="offer-car-features.origine" />:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography>{offerCar?.matriculation_country ? offerCar?.matriculation_country : '-'}</Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography color="textSecondary">
                  <FormattedMessage id="list-vo-offer-card.stockage-vehicules" />:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="notranslate">{offerCar?.campa ? offerCar?.campa : '-'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}

OfferCarFeatures.propTypes = {
  offerCar: PropTypes.object
};

export default OfferCarFeatures;
