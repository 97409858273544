import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import HorizontalBar from './Drawer/HorizontalBar';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

import useConfig from 'hooks/useConfig';
import { useMenuConfig } from 'hooks/useMenuConfig';
import { openDrawer } from 'store/reducers/menu';
import { LAYOUT_CONST } from 'config';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const { verticalNavigation } = useMenuConfig();

  const downMD = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

  const { container, miniDrawer, menuOrientation } = useConfig();
  const dispatch = useDispatch();

  const { drawerOpen, userRole } = useSelector((state) => state.menu);

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downMD;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!downMD);
      dispatch(openDrawer({ drawerOpen: !downMD }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downMD]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      {!isHorizontal && <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />}
      {isHorizontal && <HorizontalBar />}
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: 0 }}>
        <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            ...(container && { px: { xs: 0, sm: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Breadcrumbs navigation={verticalNavigation[userRole]} title titleBottom card={false} divider={false} sx={{ p: 3 }} />
          <Outlet />
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
