import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing
const HomeProvider = Loadable(lazy(() => import('pages/provider/home')));
const ListVoKanban = Loadable(lazy(() => import('pages/provider/listVoKanban')));
const ListVoCarsTable = Loadable(lazy(() => import('pages/provider/listVoCarsTable')));
const SaleOperations = Loadable(lazy(() => import('pages/provider/saleOperations')));

// ==============================|| PROVIDER ROUTING ||============================== //

const ProviderRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <HomeProvider />
    },
    {
      path: 'sales',
      children: [
        {
          path: 'my-sales',
          element: <SaleOperations />
        },
        {
          path: 'my-lists-vo',
          element: <ListVoKanban />
        },
        {
          path: 'my-lists-vo/:id/cars',
          element: <ListVoCarsTable />
        }
      ]
    }
  ]
};

export default ProviderRoutes;
