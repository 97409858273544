import { useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';

// material-ui
import { Grid, Typography } from '@mui/material';

// project import
import useAuth from 'hooks/useAuth';
import Loader from 'components/Loader';
import getCarPhoto from 'utils/carPhotos';
import { getPublicCar } from 'api/PublicCarService';
import { getCompanyInfo } from 'api/PortalScriptsService';

import MainCard from 'components/MainCard';
import PlaceOrder from 'components/product/PlaceOrder';
import FeaturedCars from 'components/product/FeaturedCars';
import ProductImages from 'components/product/ProductImages';
import OfferCarOptions from 'components/product/OfferCarOptions';
import OfferCarFeatures from 'components/product/OfferCarFeatures';
import OfferCarComments from 'components/product/OfferCarComments';
import { useTheme } from '@mui/styles';

// ==============================|| Offer Car Details ||============================== //
const ListVoOfferCarDetails = () => {
  const theme = useTheme();
  const { id } = useParams();
  const [offerCar, setOfferCar] = useState(null);
  const [carPhotos, setCarPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { company } = useAuth();

  const fetchData = useCallback(async () => {
    await getCompanyInfo({ name: company.name });
    const res = await getPublicCar(id);
    setOfferCar(res.data.data);
    setLoading(false);
  }, [company, id]);

  useEffect(() => {
    if (offerCar) {
      if (offerCar.photos?.length > 0) {
        setCarPhotos(offerCar.photos);
      } else {
        setCarPhotos([getCarPhoto(offerCar.brand)]);
      }
    }
  }, [offerCar]);

  useEffect(() => fetchData(), [fetchData]);

  if (loading) return <Loader />;
  return (
    <>
      <Grid container>
        <Grid item xs={1} lg={2} alignContent={'center'}></Grid>
        <Grid item xs={10} lg={8} alignContent={'center'}>
          <MainCard sx={{ p: 1 }}>
            <Grid container justifyContent={'center'}>
              <Grid item xs={12} md={6} xl={6}>
                {carPhotos.length > 0 && <ProductImages images={carPhotos} />}
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                {offerCar && <PlaceOrder offerCar={offerCar} />}
              </Grid>
            </Grid>
          </MainCard>
          <Grid container justifyContent={'center'} spacing={2} mt={1}>
            <Grid item xs={12} md={offerCar.description.length > 0 ? 6 : 12} xl={offerCar.description.length > 0 ? 6 : 12}>
              <OfferCarFeatures offerCar={offerCar} />
            </Grid>
            {offerCar?.description?.length > 0 && (
              <Grid item xs={12} md={6} xl={6}>
                <OfferCarOptions offerCar={offerCar} />
              </Grid>
            )}
            {offerCar?.comments?.length > 0 && (
              <Grid item xs={12} md={12} xl={12}>
                <OfferCarComments offerCar={offerCar} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={1} lg={2} alignContent={'center'}></Grid>
        <Grid
          item
          xs={12}
          lg={12}
          mt={15}
          style={{ backgroundColor: theme.palette.info.main }}
          textAlign={'center'}
          alignContent={'center'}
        ></Grid>
        <Grid item xs={1} lg={2} alignContent={'center'}></Grid>
        <Grid item xs={10} lg={8} alignContent={'center'} textAlign={'center'}>
          <Grid container>
            <Grid item xs={12}>
              <MainCard style={{ width: '100%', backgroundColor: theme.palette.primary.main }}>
                <Typography variant="h3" style={{ fontWeight: 'bold', color: 'white' }} fontFamily={'CanoGroup'}>
                  <FormattedMessage id="featured-cars-title" />
                </Typography>
              </MainCard>
            </Grid>
            <Grid item xs={12} mt={5} mb={10}>
              <FeaturedCars offerCar={offerCar} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} lg={2} alignContent={'center'}></Grid>
      </Grid>
    </>
  );
};

ListVoOfferCarDetails.propTypes = {
  id: PropTypes.string,
  status: PropTypes.array,
  row: PropTypes.object,
  showButton: PropTypes.bool
};

export default ListVoOfferCarDetails;
