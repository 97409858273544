/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, CardMedia, Grid, Stack } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
// import { Lightbox } from 'react-modal-image';
import Lightbox from 'yet-another-react-lightbox';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import IconButton from 'components/@extended/IconButton';

// third-party
import Slider from 'react-slick';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

// assets
import { ZoomInOutlined, ZoomOutOutlined, RedoOutlined, DownOutlined, UpOutlined, FullscreenOutlined } from '@ant-design/icons';

// ==============================|| PRODUCT DETAILS - IMAGES ||============================== //

const ProductImages = ({ images }) => {
  const theme = useTheme();

  const [selected, setSelected] = useState(0);
  const [modal, setModal] = useState(false);
  const [fullscreenOpen, setFullscreenOpen] = useState(false);
  const imagesGallery = images.map((item) => {
    return { src: item };
  });
  const imagesSlider = images.length < 5 ? [...images, ...images] : images;
  const lgNo = 5;

  const ArrowUp = (props) => (
    <Box
      {...props}
      color="secondary"
      className="prev"
      sx={{
        cursor: 'pointer',
        '&:hover': { bgcolor: theme.palette.info.lighter },
        bgcolor: 'transparent',
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: 1,
        p: 0.75,
        mb: 1.25,
        lineHeight: 0,
        '&:after': { boxShadow: 'none' }
      }}
    >
      <UpOutlined style={{ fontSize: 'small', color: theme.palette.info.main }} />
    </Box>
  );

  const ArrowDown = (props) => (
    <Box
      {...props}
      color="secondary"
      className="prev"
      sx={{
        cursor: 'pointer',
        '&:hover': { bgcolor: theme.palette.info.lighter },
        bgcolor: 'transparent',
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: 1,
        p: 0.75,
        mt: 1.25,
        lineHeight: 0,
        '&:after': { boxShadow: 'none' }
      }}
    >
      <DownOutlined style={{ fontSize: 'small', color: theme.palette.info.main }} />
    </Box>
  );

  const settings = {
    rows: 1,
    vertical: true,
    verticalSwiping: true,
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '0px',
    slidesToShow: images.length > 3 ? lgNo : images.length,
    prevArrow: <ArrowUp />,
    nextArrow: <ArrowDown />
  };

  const fullscrren = () => {
    setFullscreenOpen(true);
  };

  return (
    <>
      <Grid container spacing={0.5} alignItems={'center'}>
        <Grid item xs={10} md={10} lg={10}>
          <MainCard
            content={false}
            border={false}
            boxShadow={false}
            shadow="0"
            sx={{
              m: '0 auto',
              display: 'flex',
              alignItems: 'center',
              bgcolor: theme.palette.mode === 'dark' ? 'grey.50' : 'info.lighter',
              '& .react-transform-wrapper': { cursor: 'crosshair', width: '100%' },
              '& .react-transform-component': { width: '100%', overflow: 'hidden' }
            }}
          >
            <TransformWrapper initialScale={1} width={'100%'}>
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <TransformComponent>
                    <CardMedia
                      onClick={() => setModal(!modal)}
                      component="img"
                      width={'100%'}
                      image={imagesSlider[selected]}
                      title="Scroll Zoom"
                      sx={{ borderRadius: `8px`, position: 'relative', overflow: 'hidden' }}
                    />
                  </TransformComponent>
                  <Stack direction="row" spacing={1} className="tools" sx={{ position: 'absolute', bottom: 10, right: 10, zIndex: 1 }}>
                    <IconButton variant={'contained'} color="info" onClick={() => zoomIn()}>
                      <ZoomInOutlined style={{ fontSize: '1.15rem' }} />
                    </IconButton>
                    <IconButton variant={'contained'} color="info" onClick={() => zoomOut()}>
                      <ZoomOutOutlined style={{ fontSize: '1.15rem' }} />
                    </IconButton>
                    <IconButton variant={'contained'} color="info" onClick={() => resetTransform()}>
                      <RedoOutlined style={{ fontSize: '1.15rem' }} />
                    </IconButton>
                    <IconButton variant={'contained'} color="info" onClick={() => fullscrren()}>
                      <FullscreenOutlined style={{ fontSize: '1.15rem' }} />
                    </IconButton>
                  </Stack>
                </>
              )}
            </TransformWrapper>
          </MainCard>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              '& .slick-slider': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: 100,
                '& .slick-list': {
                  overflow: 'hidden'
                }
              },
              '& .slick-arrow': {
                '&:hover': { bgcolor: theme.palette.grey.A200 },
                position: 'initial',
                color: theme.palette.grey[500],
                bgcolor: theme.palette.grey.A200,
                p: 0,
                transform: 'rotate(90deg)',
                borderRadius: '50%',
                height: 17,
                width: 19
              }
            }}
          >
            <Slider {...settings}>
              {imagesSlider.map((item, index) => (
                <Box key={index} onClick={() => setSelected(index)} sx={{ p: 1 }}>
                  <Avatar
                    size={'xl'}
                    src={item}
                    variant="rounded"
                    sx={{
                      m: '0 auto',
                      cursor: 'pointer',
                      bgcolor: theme.palette.grey[0],
                      border: `1px solid ${theme.palette.grey[200]}`
                    }}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
          {fullscreenOpen && (
            <>
              <Lightbox
                slides={imagesGallery}
                open={fullscreenOpen}
                plugins={[Slideshow, Thumbnails, Zoom]}
                close={() => setFullscreenOpen(false)}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

ProductImages.propTypes = {
  images: PropTypes.array
};
export default ProductImages;
