// third-party
import { FormattedMessage } from 'react-intl';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

export const purchasesVertical = {
  id: 'group-purchases',
  tags: ['development', 'Public Client'],
  type: 'group',
  children: [
    {
      id: 'home-purchases',
      title: <FormattedMessage id="home" />,
      url: '/',
      type: 'item'
    },
    {
      id: 'all-public-cars',
      title: <FormattedMessage id="all-available-cars" />,
      url: '/public-cars',
      type: 'item'
    },
    {
      id: 'in-progress-cars',
      title: <FormattedMessage id="in-progress-cars" />,
      url: '/in-progress-cars',
      type: 'item'
    },
    {
      id: 'my-purchases',
      title: <FormattedMessage id="my-purchases" />,
      url: '/orders/my-purchases',
      type: 'item'
    }
  ]
};

export const purchasesHorizontal = {
  items: [
    {
      id: 'home-purchases',
      title: <FormattedMessage id="home" />,
      url: '/',
      type: 'item'
    },
    {
      id: 'all-public-cars',
      title: <FormattedMessage id="all-available-cars" />,
      url: '/public-cars',
      type: 'item'
    },
    {
      id: 'my-purchases',
      title: <FormattedMessage id="my-purchases" />,
      url: '/orders/my-purchases',
      type: 'item'
    }
  ]
};
