import { apiGet } from '.';

export const getCompanyInfo = (filters) => {
  const config = { responseType: 'json' };

  const query = filters
    ? encodeURI(
        Object.entries(filters)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      )
    : '';

  return apiGet(`/api/v1/portal-scripts/companies?${query}`, config);
};

export const getCompanyPerformance = (filters) => {
  const config = { responseType: 'json' };

  const query = filters
    ? encodeURI(
        Object.entries(filters)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      )
    : '';

  return apiGet(`/api/v1/portal-scripts/companies-performance?${query}`, config);
};
