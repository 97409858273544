import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Badge, Popover, Dialog } from '@mui/material';
import { usePopupState, bindPopover, bindHover } from 'material-ui-popup-state/hooks';

// assets
import { ShoppingCartOutlined } from '@ant-design/icons';

// project import
import IconButton from 'components/@extended/IconButton';
import AcceptOfferDialog from 'components/dialogs/client/AcceptOfferDialog';
import { resetCardSuccess } from 'store/reducers/cart';
import { executeRefresh } from 'store/reducers/refresh';
import NotAvailableOfferDialog from 'components/dialogs/client/NotAvailableOfferDialog';

// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const ShoppingCart = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const popupState = usePopupState({ variant: 'popover', popupId: 'cart-popover' });
  const [notAvailableCars, setNotAvailableCars] = useState([]);
  const [openNotAvailableCars, setOpenNotAvailableCars] = useState(false);

  const closeNotAvailableDialog = () => {
    setOpenNotAvailableCars(false);
  };

  const onAcceptOffer = (errorCars = undefined) => {
    dispatch(resetCardSuccess({ products: [] }));
    dispatch(executeRefresh({ needRefresh: true }));
    if (errorCars) {
      setNotAvailableCars(errorCars);
      setOpenNotAvailableCars(true);
    }
    popupState.close();
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        {...bindHover(popupState)}
        color="secondary"
        variant="light"
        sx={{
          color: 'text.primary',
          bgcolor: popupState.isOpen ? iconBackColorOpen : iconBackColor
        }}
      >
        <Badge badgeContent={cart.checkout.products.length} color="error">
          <ShoppingCartOutlined />
        </Badge>
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{ mt: '1.3rem' }}
      >
        <AcceptOfferDialog
          maxWidth="xs"
          fullWidth
          onAccept={onAcceptOffer}
          isShoppingCart={true}
          listVoCarsData={cart.checkout.products}
          sx={{
            '& .MuiDialog-paper': { p: 0 }
          }}
        />
      </Popover>
      {openNotAvailableCars && (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={closeNotAvailableDialog}>
          <NotAvailableOfferDialog
            maxWidth="sm"
            fullWidth
            closeDialog={closeNotAvailableDialog}
            open={openNotAvailableCars}
            notAvailableCarsData={notAvailableCars}
            sx={{ '& .MuiDialog-paper': { p: 0 } }}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default ShoppingCart;
