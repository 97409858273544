import { apiPost, apiGet } from '.';

export const getAllPublicCars = async () => {
  const url = `/api/v1/public-cars`;
  return apiGet(url);
};

export const getPurchasedPublicCars = async () => {
  const url = `/api/v1/public-purchase-cars`;
  return apiGet(url);
};

export const getPublicCar = async (id) => {
  const config = { responseType: 'json' };
  const url = `/api/v1/public-cars/${id}`;
  return apiGet(url, config);
};

export const getPublicCarsByProperties = async (properties) => {
  const url = '/api/v1/public-cars';

  const queryParams = new URLSearchParams(properties);

  return apiGet(`${url}?${queryParams.toString()}`);
};

export const postAcceptOffer = async (body) => {
  const url = '/api/v1/public-cars/purchase';
  return apiPost(url, body);
};

export const postOfferOffer = async (body) => {
  const url = '/api/v1/public-cars/offer';
  return apiPost(url, body);
};
