import { useState, useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router';

// material-ui
import { Box, Button, CardHeader, Divider, FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';

// third party
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import GoogleMaps from 'components/inputs/GoogleMapComponent';

// project import
import MainCard from 'components/MainCard';
// import { putUser } from 'api/UserService';

// hooks
import useAuth from 'hooks/useAuth';
import { putPublicUserCompany, postPublicUserCompanyFile } from 'api/UserService';
import useUtils from 'hooks/useUtils';

function useInputRef() {
  return useOutletContext();
}

// ==============================|| TAB - PERSONAL ||============================== //

const TabCompany = () => {
  const { company } = useAuth();
  const [id, setId] = useState(undefined);
  const idInputRef = useRef();
  const [kbis, setKbis] = useState(undefined);
  const kbisInputRef = useRef();

  const intl = useIntl();
  const { displaySnackbar } = useUtils();

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  useEffect(() => {
    if (company.id_file) {
      setId({ name: company.id_file });
    }
    if (company.kbis_file) {
      setKbis({ name: company.kbis_file });
    }
  }, [company]);

  const inRef = useInputRef();

  const handleChange = (event, fileType) => {
    const file = event.target.files[0];
    let promiseInfo = undefined;
    let promiseFile = undefined;
    switch (fileType) {
      case 'id':
        setId(file);
        promiseInfo = putPublicUserCompany(company?.id, { id: company?.id, id_file: file.name });
        promiseFile = postPublicUserCompanyFile(company?.id, file?.name, file);

        break;
      case 'kbis':
        setKbis(file);
        promiseInfo = putPublicUserCompany(company?.id, { id: company?.id, kbis_file: file.name });
        promiseFile = postPublicUserCompanyFile(company?.id, file?.name, file);
        break;

      default:
        promiseFile = postPublicUserCompanyFile(company?.id, file?.name, file).then((response) => {
          if (response?.status === 200)
            displaySnackbar(intl.formatMessage({ id: 'tab-personal.personal-profile-updated-successfully' }), 'success');
        });
        break;
    }
    if (promiseInfo && promiseFile) {
      Promise.all([promiseInfo, promiseFile]).then((responses) => {
        for (let index = 0; index < responses.length; index++) {
          if (responses[index]?.status !== 200) return false;
        }
        displaySnackbar(intl.formatMessage({ id: 'tab-personal.personal-profile-updated-successfully' }), 'success');
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: company?.name,
      address: company?.address,
      id_fiscale: company?.id_fiscale,
      country: company?.country,
      submit: null
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(255)
        .required(<FormattedMessage id="tab-personal.first-name-is-required" />),
      address: Yup.string()
        .max(255)
        .required(<FormattedMessage id="tab-personal.address-is-required" />),
      id_fiscale: Yup.string()
        .max(255)
        .required(<FormattedMessage id="tab-personal.id-fiscale-is-required" />)
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const response = await putPublicUserCompany(company?.id, { ...values, id: company?.id });
        if (response.status === 200) {
          displaySnackbar(intl.formatMessage({ id: 'tab-personal.personal-profile-updated-successfully' }), 'success');
        }
        setStatus({ success: true });
        setSubmitting(false);
      } catch (err) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  });

  return (
    <MainCard
      content={false}
      title={<FormattedMessage id="tab-personal.personal-information" />}
      sx={{ '& .MuiInputLabel-root': { fontSize: '0.875rem' } }}
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1.25}>
                <InputLabel htmlFor="tab-company.name">{<FormattedMessage id="tab-company.name" />}</InputLabel>
                <TextField
                  fullWidth
                  value={formik.values.name}
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder={intl.formatMessage({ id: 'tab-company.name' })}
                  autoFocus
                  inputRef={inRef}
                />
                {formik.touched.name && formik.errors.name && (
                  <FormHelperText error id="personal-first-name-helper">
                    {formik.errors.name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1.25}>
                <InputLabel htmlFor="tab-company.id-fiscale">{<FormattedMessage id="tab-company.id-fiscale" />}</InputLabel>
                <TextField
                  fullWidth
                  value={formik.values.id_fiscale}
                  name="id_fiscale"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  id="id-fiscale"
                  placeholder={intl.formatMessage({ id: 'tab-company.id-fiscale' })}
                />
                {formik.touched.id_fiscale && formik.errors.id_fiscale && (
                  <FormHelperText error id="id-fiscale-helper">
                    {formik.errors.id_fiscale}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="address-signup">
                  <FormattedMessage id="auth-register.company-address" />
                </InputLabel>
                <GoogleMaps
                  name="address"
                  id="address-signup"
                  initialValue={formik.values.address}
                  onChange={(newValue) => {
                    formik.setFieldValue('address', newValue?.description || '');
                    formik.setFieldValue('address_place_id', newValue?.place_id || '');
                  }}
                  setFieldValue={formik.setFieldValue}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardHeader title={<FormattedMessage id="Documments" />} />
        <Divider />
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack direction={'column'} spacing={2}>
                <InputLabel>
                  <FormattedMessage id="Kbis file" />
                </InputLabel>
                <Stack direction={'row'} spacing={1}>
                  <Button
                    variant="contained"
                    style={{
                      width: 'fit-content',
                      textWrap: 'nowrap',
                      fontWeight: 'bold'
                    }}
                    onClick={() => kbisInputRef.current.click()}
                  >
                    <FormattedMessage id="upload-file" />
                  </Button>
                  <TextField
                    id="outlined-read-only-input"
                    value={kbis ? kbis.name : 'Kbis file is needed'}
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                  />
                  <input onChange={(e) => handleChange(e, 'kbis')} multiple={false} ref={kbisInputRef} type="file" hidden />
                  {/* <Button disabled fullWidth variant="outlined">
                    ID file
                  </Button> */}
                </Stack>
              </Stack>
            </Grid>
            <Box width="100%" />

            <Grid item xs={12} sm={6}>
              <Stack direction={'column'} spacing={2}>
                <InputLabel>
                  <FormattedMessage id="ID file" />
                </InputLabel>
                <Stack direction={'row'} spacing={1}>
                  <Button
                    variant="contained"
                    style={{
                      width: 'fit-content',
                      textWrap: 'nowrap',
                      fontWeight: 'bold'
                    }}
                    onClick={() => idInputRef.current.click()}
                  >
                    <FormattedMessage id="upload-file" />
                  </Button>
                  <TextField
                    id="outlined-read-only-input"
                    value={id ? id.name : 'ID file is needed'}
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                  />
                  <input onChange={(e) => handleChange(e, 'id')} multiple={false} ref={idInputRef} type="file" hidden />
                </Stack>
              </Stack>
              {/* <Button disabled variant="outlined">
                  kbis.file
                </Button> */}
            </Grid>
            <Box width="100%" />
          </Grid>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: 2.5 }}>
            <Button variant="outlined" color="secondary">
              {<FormattedMessage id="tab-personal.cancel" />}
            </Button>
            <Button disabled={formik.isSubmitting || Object.keys(formik.errors).length !== 0} type="submit" variant="contained">
              {<FormattedMessage id="tab-personal.save" />}
            </Button>
          </Stack>
        </Box>
      </form>
    </MainCard>
  );
};

export default TabCompany;
