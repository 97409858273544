import { useState } from 'react';
import PropTypes from 'prop-types';

// third-party
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

// material-ui
import {
  Box,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
  DialogActions,
  Button,
  Grid,
  List,
  CardMedia,
  ListItemButton,
  ListItemText,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';

import useUtils from 'hooks/useUtils';
import getCarPhoto from 'utils/carPhotos';
import formattedPrice from 'utils/formatPrice';
import { removeProduct } from 'store/reducers/cart';
import { getAllPublicCars, postAcceptOffer } from 'api/PublicCarService';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router';

// ==============================|| CONFIRM DIALOG ||============================== //

export default function AcceptOfferDialog({ closeDialog, onAccept, listVoCarsData, isShoppingCart = false }) {
  // sx styles
  const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    transform: 'none'
  };

  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, company } = useAuth();

  const { displaySnackbar } = useUtils();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const removedCars = [];
    const availableCars = (await getAllPublicCars())?.data?.data;
    const carData = listVoCarsData.reduce((accumulator, currentValue) => {
      if (availableCars.find((item) => item?.chassis === currentValue.chassis)) {
        accumulator.push({
          chassis: currentValue.chassis,
          immat: currentValue.immat,
          brand: currentValue.brand,
          model: currentValue.model,
          version: currentValue.version,
          color: currentValue.color,
          campa: currentValue.campa,
          bodywork: currentValue.bodywork,
          box: currentValue.box,
          energy: currentValue.energy,
          kms: currentValue.kms,
          matriculation_date: currentValue.matriculation_date,
          matriculation_country: currentValue.matriculation_country,
          din_power: currentValue.din_power,
          date_request: new Date(),
          price: currentValue.price
        });
      } else {
        removedCars.push(currentValue);
      }
      return accumulator;
    }, []);

    try {
      await postAcceptOffer({ list_vo_offer_info: carData, user_info: user, company_info: company });
      displaySnackbar(intl.formatMessage({ id: 'message-your-request-registered' }));
      onAccept(removedCars.length > 0 ? removedCars : undefined);
    } catch (error) {
      displaySnackbar(intl.formatMessage({ id: 'message-something-went-wrong' }), 'error');
      console.error(error);
    }
  };
  const getCarPhotos = (car) => {
    if (car) {
      if (car.photos?.length > 0) {
        return car.photos[0];
      } else {
        return [getCarPhoto(car.brand)];
      }
    }
  };
  const handleDelete = (event) => {
    const carToDelete = event?.currentTarget.id;
    dispatch(removeProduct({ reference: carToDelete }));
    event.stopPropagation();
  };

  const navigateItem = (car) => {
    navigate(`/public-cars/${car.reference}`);
  };

  const totalPrice = listVoCarsData.reduce((acc, car) => acc + car.price, 0);

  const carDescriptionWidth = isShoppingCart ? 'calc(6/10*100%)' : 'calc(8/10*100%)';
  const carDescriptionMaxWidth = isShoppingCart ? 250 : 300;

  return (
    <Box sx={{ p: 1, py: 1.5, minWidth: '500px', minHeight: '200px' }}>
      <DialogTitle sx={{ p: 3, display: 'flex', justifyContent: 'start' }}>
        <Typography sx={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '1.2rem' }}>
          <FormattedMessage id="your-order" />
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 0, pr: 1 }} style={{ overflow: 'auto', maxHeight: '32vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {listVoCarsData.length ? (
              <List
                sx={{
                  py: 0,
                  '& .MuiListItemButton-root': {
                    '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                  }
                }}
              >
                {listVoCarsData.map((car, index) => (
                  <ListItemButton
                    divider={index < listVoCarsData.length - 1}
                    key={index}
                    sm={12}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={() => navigateItem(car)}
                  >
                    <Box sx={{ width: 110, mr: 1 }}>
                      <CardMedia sx={{ height: 100, width: 110, textDecoration: 'none', opacity: 1 }} image={getCarPhotos(car)} />
                    </Box>
                    <ListItemText
                      primary={<Typography className="notranslate" variant="subtitle1">{`${car.brand} ${car.model}`}</Typography>}
                      secondary={
                        <Typography variant="body2" color="text.secondary">
                          {car.version}
                        </Typography>
                      }
                      sx={{ width: carDescriptionWidth, maxWidth: carDescriptionMaxWidth }}
                    />
                    <ListItemText
                      primary={<Typography variant="subtitle1">{`${car.price !== null ? formattedPrice(car.price) : '--'}`}</Typography>}
                      sx={{ width: 'calc(2/10*100%)', pl: 1 }}
                    />
                    {isShoppingCart && (
                      <IconButton
                        id={car.reference}
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => handleDelete(e)}
                        size="small"
                        sx={{ ml: 4 }}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    )}
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <Box sx={{ p: 2, pb: 0, display: 'flex', justifyContent: 'flex-end' }}>
        <Typography variant="h5" sx={{ fontWeight: '700' }}>
          <FormattedMessage id="total" />
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: '500', pl: 1, pr: 1 }} color="secondary">
          {`(${listVoCarsData.length} ${
            listVoCarsData.length === 1
              ? intl.formatMessage({ id: 'accept-offer-dialog.car' })
              : intl.formatMessage({ id: 'accept-offer-dialog.cars' })
          })`}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: '700' }}>
          :&nbsp;&nbsp;{formattedPrice(totalPrice)}
        </Typography>
      </Box>
      <DialogActions sx={{ p: 2.5 }}>
        {!isShoppingCart && (
          <Button color="warning" onClick={closeDialog}>
            <FormattedMessage id="back" />
          </Button>
        )}
        <LoadingButton disabled={listVoCarsData.length === 0} onClick={onSubmit} type="submit" variant="contained" loading={loading}>
          <FormattedMessage id="place-order" />
        </LoadingButton>
      </DialogActions>
    </Box>
  );
}

AcceptOfferDialog.propTypes = {
  open: PropTypes.bool,
  isShoppingCart: PropTypes.bool,
  closeDialog: PropTypes.func,
  onAccept: PropTypes.func,
  listVoCarsData: PropTypes.array
};
